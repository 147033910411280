import { defineStore } from "pinia";
import { FundLayout, UmbrellaItem } from "~/types/domain/domain";
import { Currency } from "~/utils/numbers";
import { State as InvestmentsState, useInvestmentsStore } from './investments';
import { i18n } from '~/utils/i18n'
import { compareDesc } from "date-fns";
import { formatDate } from "~/utils/dates";

export interface State {
  selectedCategory: string,
  keyNumbers: {
    value: number,
    code: string
  }[]
  investmentsByVintageYear: {
    products: {
      name: string
      currency: Currency
    }[]
    dates: string[]
    items: {
      data: number[]
      stack?: string
      code: string
    }[]
  } | null
}

export const usePortfolioStore = defineStore('portfolio', {
  state: (): State => {
    return {
      selectedCategory: 'ALL',
      keyNumbers: [],
      investmentsByVintageYear: null
    }
  },
  getters: {
    umbrellasFiltered (state): UmbrellaItem[] {
      if (state.selectedCategory === 'ALL') {
        let umbrellas: UmbrellaItem[] = []
        const investmentsStore = useInvestmentsStore()
        investmentsStore.categories.forEach(category => {
          if (category.umbrellas) {
            umbrellas = [...umbrellas, ...category.umbrellas]
          }
        })

        return umbrellas
      }

      return this.selectedCategoryObject?.umbrellas ?? []
    },
    selectedCategoryObject (state): InvestmentsState['categories'][0] {
      const investmentsStore = useInvestmentsStore()
      if (state.selectedCategory === 'ALL') {
        return {
          code: 'ALL',
          name: i18n.t('words.all'),
          current: true,
          layout: FundLayout.PRIVATE_EQUITY,
          umbrellas: this.umbrellasFiltered
        }
      }

      return investmentsStore.categories.find(category => category.code === state.selectedCategory) as InvestmentsState['categories'][0]
    },
    lastNavDate (): string | null {
      try {
        const funds = (this.umbrellasFiltered as UmbrellaItem[]).map(umbrella => umbrella.funds).flat()
        let shares = funds.map(fund => fund.shares).flat()
        shares = shares.sort((a, b) => compareDesc(new Date(a.lastDate.value), new Date(b.lastDate.value)))

        if (shares[0].lastDate.value) {
          return formatDate(new Date(shares[0].lastDate.value))
        }
        return null
      } catch {
        return null
      }
    }
  }
})
