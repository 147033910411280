import * as CAPZA from './CAPZA.json'
import * as DEMO_RETAIL from './DEMO_RETAIL.json'
import * as DEMO from './DEMO.json'
import * as EDR from './EDR.json'
import * as INDOSUEZ from './INDOSUEZ.json'
import * as MONETA from './MONETA.json'
import * as ODDO from './ODDO.json'
import * as ROCHE_DUBAR from './ROCHE_DUBAR.json'
import * as BELFIUS from './BELFIUS.json'
import { z } from 'zod'
import { Theme } from '~/types/theme'

export const defaultThemes = {
  CAPZA,
  DEMO_RETAIL,
  DEMO,
  EDR,
  INDOSUEZ,
  MONETA,
  ODDO,
  ROCHE_DUBAR,
  BELFIUS
} as Record<string, z.infer<typeof Theme>>
