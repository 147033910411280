<template>
  <div class="h-full bg-gray-50">
    <div class="min-h-full">
      <main class="-mt-24 pb-8">
        <slot />
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
import mixpanel from "mixpanel-browser";

const config = useRuntimeConfig()

onBeforeMount(() => {
  mixpanel.init(config.public.MIXPANEL_TOKEN, {
    persistence: 'cookie',
    cross_subdomain_cookie: true,
    debug: process.env.NODE_ENV === 'development',
    ignore_dnt: true
  })
})
</script>
