export enum S3EndpointCode {
  AIRFUND = 'AIRFUND_S3_ENDPOINT_URL',
  AIRWEALTH = 'AIRWEALTH_S3_ENDPOINT_URL'
}

export const getPublicUrl = (id: string, domain: string = S3EndpointCode.AIRFUND): string | null => {
  const config = useRuntimeConfig()
  const url = /^https?:\/\//i;
  if (id && !url.test(id)) {
    return `${config.public[domain]}/public/${id}`;
  }
  return null
}

export const getAirfundDocument = async (ressourceId: string): Promise<string|null> => {
  const { getSession } = useAuth()
  const session = await getSession()
  const headers = {
    'Authorization': `Bearer ${session.accessToken}`,
    'Content-Type': 'application/json'
  }
  const config = useRuntimeConfig()
  const domain = S3EndpointCode.AIRFUND

  try {
    const { data }: any = await useFetch(`${config.public[domain]}/${ressourceId}`, {
      headers,
      method: 'POST'
    })
    return data.value.url
  } catch {
    return null
  }
}

export const getAirwealthDocument = async (body: { id: string, hash: string, name: string }): Promise<string|null> => {
  const { getSession } = useAuth()
  const session = await getSession()
  const headers = {
    'Authorization': `Bearer ${session.accessToken}`,
    'Content-Type': 'application/json'
  }
  const config = useRuntimeConfig()
  const domain = S3EndpointCode.AIRWEALTH

  try {
    const { data }: any = await useFetch(config.public[domain], {
      headers,
      method: 'POST',
      body
    })
    return data.value.url
  } catch {
    return null
  }
}

export const removeAirfundDocument = async (ressourceId: string): Promise<boolean> => {
  const { getSession } = useAuth()
  const session = await getSession()
  const headers = {
    'Authorization': `Bearer ${session.accessToken}`,
    'Content-Type': 'application/json'
  }
  const config = useRuntimeConfig()
  const domain = S3EndpointCode.AIRFUND

  try {
    const { data }: any = await useFetch(`${config.public[domain]}/${ressourceId}`, {
      headers,
      method: 'DELETE'
    })

    return data.value ? true : false
  } catch {
    return false
  }
}

export const removeAirwealthDocument = async (ressourceId: string): Promise<boolean> => {
  const { getSession } = useAuth()
  const session = await getSession()
  const headers = {
    'Authorization': `Bearer ${session.accessToken}`,
    'Content-Type': 'application/json'
  }
  const config = useRuntimeConfig()
  const domain = S3EndpointCode.AIRWEALTH

  try {
    const { data }: any = await useFetch(`${config.public[domain]}/remove`, {
      headers,
      method: 'DELETE',
      body: {
        hashes: [ressourceId]
      }
    })

    return data.value ? true : false
  } catch {
    return false
  }
}

export const uploadAirwealthDocument = async (file: File): Promise<{ hash: string, url: string }|null> => {
  try {
    const { getSession } = useAuth()
    const session = await getSession()
    const headers = {
      'Authorization': `Bearer ${session.accessToken}`,
      'Accept': 'application/json'
    }
    const formData = new FormData()
    formData.append('file', file)
    const domain = S3EndpointCode.AIRWEALTH
    const config = useRuntimeConfig()

    return await $fetch(`${config.public[domain]}/upload?public=false`, {
      method: 'POST',
      headers,
      body: formData
    })

  } catch {
    return null
  }
}

export const uploadAirfundImage = async (file: File): Promise<{ id: string, url: string }|null> => {
  try {
    const { getSession } = useAuth()
    const session = await getSession()
    const headers = {
      'Authorization': `Bearer ${session.accessToken}`,
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/octet-stream'
    }
    const domain = S3EndpointCode.AIRFUND
    const config = useRuntimeConfig()

    return await $fetch(`${config.public[domain]}/?category=image&typeName=image&fileName=${file.name}`, {
      method: 'POST',
      headers,
      body: file
    })

  } catch {
    return null
  }
}

export const getAirwealthZip = async (body: { hash: string[], name: string }): Promise<string|null> => {
  const { getSession } = useAuth()
  const session = await getSession()
  const headers = {
    'Authorization': `Bearer ${session.accessToken}`,
    'Content-Type': 'application/json'
  }
  const config = useRuntimeConfig()
  const domain = S3EndpointCode.AIRWEALTH

  try {
    const { data }: any = await useFetch(config.public[domain], {
      headers,
      method: 'POST',
      body
    })
    return data.value.url
  } catch {
    return null
  }
}
