<template>
  <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl flex items-center border-t border-gray-200">
    <div class="text-sm text-gray-500 text-center sm:text-left py-5">
      <div v-if="disclaimer" class="pb-2.5 text-justify sm:text-left" v-html="disclaimer"></div>
      <div class="block sm:inline">
        <span>&copy; {{ new Date().getFullYear() }} AirFund </span>
        <span>{{ t('words.all_rights_reserved') }}.</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useCompanyStore } from '~/store/company'

const { t, locale } = useI18n()
const companyStore = useCompanyStore()

const disclaimer = computed(() => {
  try {
    return companyStore.settings.FOOTER_DISCLAIMER[locale.value]
  } catch {
    return ''
  }
})

</script>
