import {
  fromUnixTime,
  format,
  differenceInDays,
  addDays,
  isBefore,
  formatDistanceToNow
} from 'date-fns'
import { toZonedTime } from 'date-fns-tz';
import { i18n } from '~/utils/i18n'
import { fr, enGB } from 'date-fns/locale'

const UnixDateTime = 86400;
export type Locale = 'fr' | 'en'

export enum DateFormat {
  YYYYMMDD = "yyyy-MM-dd",
  DDMMYYYY = "dd/MM/yyyy",
  DMMMMYYYY = "d MMMM yyyy",
  YYYY = "yyyy",
  DMMMMYYYY_HHMM = "d MMMM yyyy HH:mm",
}

export const convertUnixDate = (value: number, dateFormat?: DateFormat): string | null => {
  if (!dateFormat) {
    dateFormat = getDateFormat(i18n.locale.value)
  }
  if (value) {
    let unixDate
    // Dépendamment de l'entité, on peut avoir des unix dates en jour ou en secondes
    if (value < 1e5) {
      unixDate = fromUnixTime(value * UnixDateTime)
    } else {
      unixDate = fromUnixTime(value)
    }
    return format(unixDate, dateFormat, { locale: getDateFnsLocale() })
  }
  return null
}

export const convertTimestampDate = (value: number, dateFormat?: DateFormat): string | null => {
  if (!dateFormat) {
    dateFormat = getDateFormat(i18n.locale.value)
  }
  if (value) {
    return format(fromUnixTime(value), dateFormat, { locale: getDateFnsLocale() })
  }
  return null
}

export const getDateFnsLocale = () => {
  if (i18n.locale.value === 'fr') {
    return fr
  }
  return enGB
}

export const getDateFormat = (locale?: Locale): DateFormat => {
  if (locale === 'fr') {
    return DateFormat.DDMMYYYY
  }
  return DateFormat.YYYYMMDD
}

export const convertDateToEpochDay = (date: Date | number): number | null => {
  if (date) {
    return differenceInDays(
      date,
      new Date(1970, 0, 1)
    )
  }
  return null
}

export const getTodayInEpochDay = (): number => {
  const today = new Date()
  const parisTime = toZonedTime(today, 'Europe/Paris')

  return convertDateToEpochDay(parisTime) as number
}

export const convertEpochDayToDate = (epochDay: number, dateFormat?: DateFormat): string | null => {
  if (!dateFormat) {
    dateFormat = getDateFormat(i18n.locale.value)
  }
  if (epochDay) {
    const date = addDays(new Date(1970, 0, 1), epochDay)
    return format(date, dateFormat, { locale: getDateFnsLocale() })
  }
  return null
}

export const isDateBefore = (date: Date | number | null, dateToCompare: Date | number | null): boolean | null => {
  if (date && dateToCompare) {
    return isBefore(date, dateToCompare)
  }
  return null
}

export const formatDate = (date: Date | number | null, dateFormat?: DateFormat): string | null => {
  if (!dateFormat) {
    dateFormat = getDateFormat(i18n.locale.value)
  }
  if (date) {
    return format(date, dateFormat, { locale: getDateFnsLocale() })
  }
  return null
}

export const formatDistanceToNowLocalized = (value: number): string => {
  return formatDistanceToNow(fromUnixTime(value), { addSuffix: true, locale: getDateFnsLocale() })
}
