export const humanizeBigNumber = (val: number, currency?: string, options?: { maximumFractionDigits?: number, minimumFractionDigits?: number, forceType?: HumanizeOptions['forceType'] }): string => {
  let numberFormatted: string
  if (options?.forceType) {
    if (options.forceType === "MILLION") {
      numberFormatted = new Intl.NumberFormat('fr-FR', {
        maximumFractionDigits: 2,
        ...options
      }).format(val / 1000000) + " M"
    }
    if (options.forceType === "KILO") {
      numberFormatted = new Intl.NumberFormat('fr-FR', {
        maximumFractionDigits: 2,
        ...options
      }).format(val / 1000) + " k"
    }
  } else if (val < 10000) {
    numberFormatted = new Intl.NumberFormat('fr-FR', {
      ...options
    }).format(val)
  } else {
    numberFormatted = new Intl.NumberFormat('fr-FR', {
      maximumFractionDigits: 2,
      notation: 'compact',
      ...options
    }).format(val)
  }

  if (currency) {
    return `${numberFormatted}${currency}`
  }
  return numberFormatted
}

export type Currency = 'EUR' | 'USD' | 'GBP'

export type HumanizeOptions = {
  forceType?: "MILLION" | "KILO" | "NONE"
}

export type OptionFormatNumber = {
  fallback?: string,
  signed?: boolean,
  append?: string,
  prepend?: string,
  humanize?: boolean | HumanizeOptions,
  decimals?: number,
  fillDecimals?: boolean,
}

export const formatNumber = (
  val: number | null | undefined,
  opt: OptionFormatNumber = {}
): string => {
  const defaultOpt = { fallback: '-', signed: false }
  const options = { ...defaultOpt, ...opt }
  if (Number.isNaN(val) || val === null || val === undefined) {
    return options.fallback
  } else {
    let sign = ''
    if (options.signed && val > 0) {
      sign = '+'
    }
    let append = ''
    if (options.append) {
      append = options.append
    }
    let prepend = ''
    if (options.prepend) {
      prepend = options.prepend
    }
    let humanize : OptionFormatNumber["humanize"] = false
    if (options.humanize) {
      humanize = options.humanize
    }
    let fillDecimals = false
    if (options.fillDecimals) {
      fillDecimals = options.fillDecimals
    }
    let formatted
    const minimumFractionDigits = fillDecimals ? options.decimals : undefined
    const maximumFractionDigits = options.decimals
    if (humanize && (humanize as HumanizeOptions)?.forceType !== "NONE") {
      formatted = humanizeBigNumber(
        val,
        null,
        {
          minimumFractionDigits,
          maximumFractionDigits,
          forceType: (humanize as HumanizeOptions)?.forceType
        }
      )
    } else {
      formatted = new Intl.NumberFormat('fr-FR', { minimumFractionDigits, maximumFractionDigits }).format(val)
    }
    return `${prepend}${sign}${formatted}${append}`
  }
}

export const formatCurrency = (val: number, currency: string, opt = {} as OptionFormatNumber): string => {
  return formatNumber(val, {...opt, append: currency})
}

export const getCurrencySymbol = (code: Currency): string => {
  switch (code) {
    case 'EUR':
      return '€'
    case 'USD':
      return '$'
    case 'GBP':
      return '£'
    default:
      return '€'
  }
}