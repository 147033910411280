import { defineStore } from "pinia";
import { Product, ProductShare } from "~/types/domain/domain";

export interface State {
  filters: {
    assetClasses: FilterItem[],
    strategies: FilterItem[],
    sfdrs: FilterItem[]
  },
  selectedFilters: {
    assetClasses: FilterItem[],
    strategies: FilterItem[],
    sfdrs: FilterItem[]
  },
  products: Product[]
}

interface FilterItem {
  value: string
  name: string
}

export const useProductsStore = defineStore('products', {
  state: (): State => {
    return {
      filters: {
        assetClasses: [],
        strategies: [],
        sfdrs: []
      },
      selectedFilters: {
        assetClasses: [],
        strategies: [],
        sfdrs: []
      },
      products: []
    }
  },
  actions: {
    resetSelectedFilters() {
      this.selectedFilters = {
        assetClasses: [],
        strategies: [],
        sfdrs: []
      }
    },
    getProductIndicator: (product: Product, code: string) : Product['indicators'][0] => {
      return product.indicators.find(i => i.code === code)
    },
    getProductCaracteristic: (product: Product, code: string) : Product['caracteristics'][0] => {
      return product.caracteristics.find(i => i.code === code)
    },
    getProductShareCaracteristic: (share: ProductShare, code: string): ProductShare['caracteristics'][0] => {
      return share.caracteristics.find(i => i.code === code)
    }
  },
  getters: {
    isFiltersEmpty: (state: State) => {
      return Object.values(state.filters).every(items => items.length === 0)
    }
  }
})
