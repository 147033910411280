import { defineStore } from "pinia";
import { i18n } from '~/utils/i18n';
import { useCompanyStore } from './company';

export interface State {
  navigation: {
    name: string
    to: string
    current: boolean
    code: string
    disabled?: boolean
  }[],
}
export const useCoreStore = defineStore('core', {
  state: (): State => {
    return {
      navigation: [
        { name: i18n.t('menu.home'), to: '/', current: true, code: 'home' },
        { name: i18n.t('menu.investments'), to: "/investments", current: false, code: 'investments' },
        { name: i18n.t('menu.operations'), to: "/transactions", current: false, code: 'transactions' },
        { name: i18n.t('menu.documents'), to: "/documents", current: false, code: 'documents' },
        { name: i18n.t('menu.news'), to: "/news", current: false, code: 'news' },
        { name: i18n.t('menu.products'), to: "/products", current: false, code: 'products' },
      ]
    }
  },
  getters: {
    getNavigation: (state: State): State['navigation'] => {
      const companyStore = useCompanyStore()

      if (!companyStore.isDataAvailable) {
        ['investments', 'transactions'].forEach(code => {
          const item = state.navigation.find(item => item.code === code)
          if (item) {
            item.disabled = true
          }
        })
      }

      let exclude: string[] = []
      if (!companyStore.settings.ENABLE_PRODUCTS) {
        exclude.push('products')
      }
      if (!companyStore.settings.DISPLAY_CAPITAL_CALLS) {
        exclude.push('investments', 'transactions')
      }

      return state.navigation.filter(item => !exclude.includes(item.code))
    }
  }
})
