import { Currency } from "~/utils/numbers"
import { Date } from "~/types/base"

export interface KeyNumber {
  label: string
  value: string
}

export interface CategoryItem {
  name: string
  code: string
  current: boolean
  layout: FundLayout
  umbrellas?: UmbrellaItem[]
}

export enum FundLayout {
  PRIVATE_EQUITY = 'PRIVATE_EQUITY',
}

export interface UmbrellaItem {
  name: string
  code: string
  funds: FundItem[]
}

export interface FundItem {
  id: string
  name: string
  category: string
  tags: string[]
  shares: ShareItem[]
  assetClass?: 'CAPITAL_INVESTMENT' | 'INFRASTRUCTURE' | 'PRIVATE_DEBT' | 'REAL_ESTATE'
}

export interface ShareItem {
  id: string
  name: string
  lastDate: Date
  currency: Currency
  indicators: IndicatorItem[]
  transactions: {
    totalSubscription: Subscription
    subscriptions: Subscription[]
    calls: CallItem[]
    distributions: DistributionItem[]
    redemptionsAndTransfers: (RedemptionItem|TransferItem)[]
  }
  positions: PositionItem[]
}

export interface InvestmentsDocument {
  id: string
  ressourceId: string
  title: string
  file: string
  type: string
  date: Date
  year: string
  fund: {
    id: string
    name: string
    categoryId: string
  }
  share: {
    id: string
    name: string
  }
}

export interface NoticeDocument {
  id: string
  ressourceId: string
  status: string
  title: string
  file: string
  type: string
  date: Date
  cashEventDate: Date
  year: string
  fund: {
    id: string
    name: string
    categoryId: string
  }
  share: {
    id: string
    name: string
  }
}

export interface IndicatorItem {
  code: string
  value: {
    value: number | null
    display: string
  }
}

export interface CallItem {
  date: Date
  description?: string
  amount: {
    value: number
    display: string
  }
  pct: {
    value: number
    display: string
  }
  notificationId?: string
  code: string
}

export interface DistributionItem {
  share: string
  code: string
  date: Date
  amount: {
    value: number
    display: string
  }
  amountPercentage: {
    value: number
    display: string
  }
  shareAmount: {
    value: number
    display: string
  }
  nav: {
    value: number
    display: string
  }
  description?: string
  notificationId?: string
}

export interface RedemptionItem {
  share: string
  type: string
  description: string
  date: Date
  shareAmount: {
    value: number
    display: string
  }
  unitPrice: {
    value: number
    display: string
  }
  amount: {
    value: number
    display: string
  }
}

export interface TransferItem extends RedemptionItem {}

export interface PositionItem {
  breakdown: {
    display: string
    value: number
  }
  name: string
  tags: string[]
  valuation: {
    value: number
    display: string
  }
}

export interface Subscription {
  date: Date
  engagement: {
    value: number
    display: string
  }
}

export interface Notification {
  id: string
  type: NotificationType // TODO: add 'newDocument' type when airfund will be able to create notifications for new documents
  description: string
  active: boolean
  date: Date,
}

export enum NotificationType {
  CALL = 'call',
  DISTRIBUTION = 'distribution',
  RETURN_OF_CALL = 'returnOfCall',
  DOCUMENT_REQUESTED = 'documentRequested',
  CASHEVENT_NOTICE_SENT = 'notice',
}

export interface Product {
  id: string
  name: string
  description: string
  managementObjectives: string
  image: string
  indicators: IndicatorItem[]
  caracteristics: CaracteristicItem[],
  shares: ProductShare[]
  documents: {
    title: string
    date: string
    type: string
    file: string
    ressourceId: string
  }[]
  labels: string[]
}
export interface ProductShare {
  extension: string
  isin: string
  caracteristics: CaracteristicItem[]
}
export interface CaracteristicItem {
  code: string
  value: string | string[]
}

export interface LeadInputs {
  duration: string
  amount: number
  message: string
}

export interface SubscriptionItem {
  id: string
  fund: {
    name: string
    currency: Currency
  }
  date: Date
  engaged: number
  documents: {
    signed: SubscriptionDocument[]
    supporting: SubscriptionDocument[]
  }
}

export interface SubscriptionDocument {
  id: string
  ressourceId: string
  file: string
  date: Date
}

export interface Operation {
  date: {
    value: string
    display: string
  }
  description: string
  amount: {
    value: number
    display: string
  }
}