export const saveDocument = async ({ url, data, fileName }: { url: string, data: BlobPart, fileName: string }) => {
  if (url && data) {
    const blob = new Blob([data])
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = fileName
    link.click()
    URL.revokeObjectURL(link.href)
  } else {
    throw new Error('No data')
  }
}

export const cleanFileName = (fileName: string): string => {
  return fileName.replace('_NOEMAIL_', '_')
}