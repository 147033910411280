<template>
	<div class="flex justify-between">
		<nav class="flex space-x-4">
			<template
				v-for="item in mainTabs"
				:key="item.name"
			>
				<div
					v-if="item.disabled"
					class="flex justify-start items-center gap-1 link text-sm rounded-md bg-opacity-0 px-3 py-2 hover:bg-opacity-10"
					:class="{ 'is-scrolled': isScrolled, 'cursor-not-allowed': item.disabled }"
				>
					<span
						:aria-label="t('words.not_enough_data')"
						data-microtip-position="top"
						role="tooltip"
					>
						<p class="opacity-50">{{ item.name }}</p>
					</span>
				</div>
				<NuxtLink
					v-else
					:to="item.to"
					class="flex justify-start items-center gap-1 link text-sm rounded-md bg-opacity-0 px-3 py-2 hover:bg-opacity-10"
					:class="{ 'is-scrolled': isScrolled }"
					:aria-current="item.current ? 'page' : undefined"
				>
					<span>{{ item.name }}</span>
				</NuxtLink>
			</template>
		</nav>
		<nav v-if="secondaryTabs.length > 0">
			<NuxtLink
				v-for="item in secondaryTabs"
				:key="item.name"
				:to="item.to"
				class="flex justify-start items-center gap-1 link text-sm rounded-md bg-opacity-0 px-3 py-2 hover:bg-opacity-10"
				:class="{ 'is-scrolled': isScrolled }"
				:aria-current="item.current ? 'page' : undefined"
			>
				<span>{{ item.name }}</span>
			</NuxtLink>
		</nav>
	</div>
</template>

<script setup lang="ts">
import { useCoreStore } from '~/store/core';
import { useI18n } from 'vue-i18n';

const mainTabs = computed(() => {
	return coreStore.getNavigation.filter(item => item.code !== 'products')
})

const secondaryTabs = computed(() => {
	const tabs = []
	const productsTab = coreStore.getNavigation.find(item => item.code === 'products')
	if (productsTab) {
		tabs.push(productsTab)
	}
	return tabs
})

const { t } = useI18n()
const coreStore = useCoreStore()
const isScrolled = inject<boolean>('isScrolled')
</script>

<style scoped>
.link.router-link-exact-active {
  @apply text-header-nav-active bg-header-nav-bg ring-primary-300;
}
.link:not(.router-link-exact-active) {
	@apply text-header-nav-normal;
}

.is-scrolled:not(.router-link-exact-active) {
	@apply text-header-nav-scrolled;
}
</style>