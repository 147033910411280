<template>
  <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 h-screen">
    <slot/>
  </div>
</template>

<script setup lang="ts">
import { useCompanyStore } from "~/store/company";
import { objectConfigToCSSVariables } from '~/utils/theming';

const companyStore = useCompanyStore()

const style = document.documentElement.style
const cssVariables = objectConfigToCSSVariables(companyStore.theme)
Object.entries(cssVariables).forEach(([key, value]) => {
  style.setProperty(key, value)
})

</script>