import { defineStore } from 'pinia';
import { CategoryItem, FundItem, IndicatorItem, PositionItem, ShareItem } from '~/types/domain/domain';

export interface State {
  categories: CategoryItem[],
  positions?: PositionItem[]
}

export const shareIndicator = (share: ShareItem, code: string) : IndicatorItem['value'] => {
  return share.indicators?.find(i => i.code === code)?.value ?? { value: null, display: '-' }
}

export const useInvestmentsStore = defineStore('investments', {
  state: (): State => {
    return {
      categories: [],
      positions: []
    }
  },
  getters: {
    getFundsList (state): FundItem[] {
      const fundsList = []
      state.categories.forEach(category => {
        category.umbrellas?.forEach(umbrella => {
          umbrella.funds.forEach(fund => {
            fundsList.push(fund)
          })
        })
      })
      return fundsList
    },
    getFundsIdList (): FundItem["id"][] {
      return this.getFundsList.map(fund => fund.id)
    },
    getSharesList (): Array<ShareItem & { category: string }> {
      let sharesList = []
      this.getFundsList.forEach((fund: FundItem) => {
        sharesList = [...sharesList, ...fund.shares.map(share => {
          return { ...share, category: fund.category }
        })]
      })
      return sharesList.sort((a, b) => a.name.localeCompare(b.name))
    },
    categoriesList (state): { id: string, name: string }[] {
      return state.categories.map(category => { 
        return { id: category.code, name: category.name }
      })
    },
    isShareIndicatorsAvailable (): boolean {
      return this.getSharesList.some(share => share.indicators.length > 0)
    }
  }
})
