import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AOuQ1DYzjk from "/usr/src/app/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import motion_fmZWau4t0U from "/usr/src/app/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import echarts_client_0ubMsSY37x from "/usr/src/app/plugins/echarts.client.ts";
import floating_vue_client_2c4Sn6eaXt from "/usr/src/app/plugins/floating-vue.client.ts";
import sentry_client_shVUlIjFLk from "/usr/src/app/plugins/sentry.client.ts";
import vue_i18n_gDNu8oDpbl from "/usr/src/app/plugins/vue-i18n.ts";
import vue_pdf_embed_client_gnZnktPI7p from "/usr/src/app/plugins/vue-pdf-embed.client.ts";
import vue_toastification_gZJw6dQQf2 from "/usr/src/app/plugins/vue-toastification.ts";
import xstate_inspect_client_Mpp0rjcr7k from "/usr/src/app/plugins/xstate-inspect.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_AOuQ1DYzjk,
  motion_fmZWau4t0U,
  chunk_reload_client_UciE0i6zes,
  echarts_client_0ubMsSY37x,
  floating_vue_client_2c4Sn6eaXt,
  sentry_client_shVUlIjFLk,
  vue_i18n_gDNu8oDpbl,
  vue_pdf_embed_client_gnZnktPI7p,
  vue_toastification_gZJw6dQQf2,
  xstate_inspect_client_Mpp0rjcr7k
]