import * as Sentry from '@sentry/vue';
import { browserTracingIntegration, replayIntegration } from '@sentry/vue';

function getSentryIntegrations() {
  const router = useRouter();
  const browserTracing = browserTracingIntegration({ router });
  const replay = replayIntegration({
    maskAllText: true,
    blockAllMedia: true
  })
  return [browserTracing, replay];
}


export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp;
  const host = window.location.host;
  let environment = 'production';
  if (import.meta.dev) {
    environment = 'development';
  }
  if (host.includes('.dev.airfund.io')) {
    environment = 'dev'
  }
  if (host.includes('.uat.airfund.io')) {
    environment = 'uat'
  }

  Sentry.init({
    app: [vueApp],
    dsn: 'https://138090877b7046d2a9109ac662814cf9@o423840.ingest.sentry.io/4504916154318848',
    environment,
    enabled: process.dev ? false : true,
    integrations: getSentryIntegrations(),
    tracesSampleRate: 1.0,
    beforeSend (event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        // eslint-disable-next-line no-console
        console.error(`[Exception handled by Sentry]: (${hint.originalException})`, { event, hint });
      }
      // Continue sending to Sentry
      return event;
    }
  });

  vueApp.mixin(
    Sentry.createTracingMixins(
      {
        trackComponents: true,
        timeout: 2000,
        hooks: ['activate', 'mount', 'update']
      }
    )
  );
  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update']
  });

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException
    }
  };
});