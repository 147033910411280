<template>
  <div class="bg-cover sm:h-auto z-40">
    <!-- DESKTOP -->
    <div class="hidden lg:block shadow-slate-200" :class="{ 'drop-shadow-md': isScrolled }">
      <div
        class="transition-all delay-50 w-full h-12 flex items-center"
        :class="[
          isScrolled ? 'bg-header-topbar-main' : 'bg-header-topbar-transparent',
          { 'border-2 border-red-600': companyStore.user.impersonationMode }
        ]"
      >
        <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 w-full">
          <div class="relative flex flex-wrap items-center justify-center lg:justify-between">
            <div class="absolute left-0 top-0 flex-shrink-0 lg:static">
              <div class='flex items-center gap-4'>
                <div class="flex-col flex sm:flex-row items-center space-x-2">
                  <h2 class="text-md font-medium leading-5 sm:leading-7 text-gray-900 lg:text-header-topbar-text opacity-100">
                    {{ t('ui.investor_portal') }} {{ companyStore.name }}
                  </h2>
                  <hr class='w-4 opacity-50' />
                  <template v-if="companyStore.user.impersonationMode">
                    <h2 class="text-md font-medium leading-5 sm:leading-7 text-gray-900 lg:text-header-topbar-text opacity-100 uppercase">
                      {{ t('words.support_mode') }}
                    </h2>
                    <hr class='w-4 opacity-50' />
                  </template>
                  <BaseInvestorSelector />
                  <p
                    v-if="displayCoInvestorTag()"
                    class="inline-flex items-center rounded-md bg-gray-100 px-2 py-0.5 text-xs font-medium text-gray-600"
                  >
                    {{ t(`menu.ownershipTypes.${companyStore.selectedInvestor.coInvestorType.toLowerCase()}`) }} ({{ companyStore.selectedInvestor.coInvestorRate }}%)
                  </p>
                </div>
              </div>
            </div>
            <div class="lg:ml-4 lg:flex lg:items-center lg:py-1 lg:pr-0.5">
              <!-- Profile dropdown -->
              <Menu as="div" class="ml-4 relative flex-shrink-0">
                <div>
                  <MenuButton class="bg-white rounded-full flex text-sm ring-1 ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100">
                    <span class="sr-only"></span>
                    <img class="h-8 w-8 rounded-full" :src="user.imageUrl" alt="" />
                  </MenuButton>
                </div>
                <transition leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                  <MenuItems class="origin-top-right z-50 absolute -right-2 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <MenuItem v-if="companyStore.settings.ENABLE_SETTINGS" key="settings">
                      <div v-if="companyStore.user.impersonationMode" class="block px-4 py-2 text-sm text-gray-700 cursor-not-allowed opacity-50">
                        {{ t('menu.settings') }}
                      </div>
                      <NuxtLink
                        v-else
                        @click='to("/settings")'
                        class="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                      >
                        {{ t('menu.settings') }}
                      </NuxtLink>
                    </MenuItem>
                    <MenuItem key="switchLocale">
                      <NuxtLink
                        @click='switchLocale'
                        class="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                      >
                        {{ t('ui.switch_locale') }}
                      </NuxtLink>
                    </MenuItem>
                    <MenuItem key="logout">
                      <NuxtLink @click='to("/logout")' class="block px-4 py-2 text-sm text-gray-700 cursor-pointer">{{ t('ui.logout') }}</NuxtLink>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div class="transition-all delay-50 w-full flex items-center bg-white" :class="isScrolled ? 'bg-opacity-100' : 'bg-opacity-0'">
        <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 w-full">
          <div class="relative flex flex-wrap items-center justify-center lg:justify-between">
            <div class="w-full py-5 lg:border-opacity-20" v-motion-fade>
              <div class="lg:grid lg:grid-cols-3 lg:gap-8 lg:items-center">
                <div class="hidden lg:block lg:col-span-3">
                  <NavMenu/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MOBILE -->
    <div class="lg:hidden">
      <div class="flex justify-between px-4 py-5 sm:px-6">
        <div>
          <h2 class="text-md mb-2 font-medium leading-5 sm:leading-7 text-gray-900 lg:text-header-topbar-text opacity-100">
            {{ t('ui.investor_portal') }} {{ companyStore.name }}
          </h2>
          <template v-if="companyStore.user.impersonationMode">
            <h2 class="text-md mb-2 font-medium leading-5 sm:leading-7 text-gray-900 lg:text-header-topbar-text opacity-100 uppercase">
              {{ t('words.support_mode') }}
            </h2>
          </template>
          <div class="flex gap-2">
            <BaseInvestorSelector />
            <p
              v-if="displayCoInvestorTag()"
              class="inline-flex items-center rounded-md bg-gray-100 px-2 py-0.5 text-xs font-medium text-gray-600"
            >
              {{ t(`menu.ownershipTypes.${companyStore.selectedInvestor.coInvestorType.toLowerCase()}`) }} ({{ companyStore.selectedInvestor.coInvestorRate }}%)
            </p>
          </div>
        </div>
        <Popover 
        v-slot="{ open }"
      >
        <!-- Burger Menu -->
        <div class="flex items-center">
          <PopoverButton class="bg-transparent rounded-md inline-flex items-center justify-center text-header-splash  hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white">
            <Icon name="heroicons:bars-3" class="block h-6 w-6" aria-hidden="true" />
          </PopoverButton>
        </div>
        <!-- Modale -->
        <TransitionRoot as="template" :show="open">
          <div class="lg:hidden">
            <TransitionChild as="template" enter="duration-150 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-150 ease-in" leave-from="opacity-100" leave-to="opacity-0">
              <PopoverOverlay class="z-20 fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>
            <TransitionChild as="template" enter="duration-150 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-150 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
              <PopoverPanel focus class="z-50 absolute top-0 inset-x-0 max-w-3xl mx-auto w-full p-4 transition transform origin-top" v-slot="{ close }">
                <div
                  class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200"
                  :class="{ 'border border-red-600': companyStore.user.impersonationMode }"
                >
                  <div class="pt-3 pb-2">
                    <div class="flex items-center justify-between px-4">
                      <div>
                        {{ t('ui.investor_portal') }} {{ companyStore.name }}
                      </div>
                      <template v-if="companyStore.user.impersonationMode">
                        <hr class='w-4 border-black' />
                        <div class="uppercase">
                          {{ t('words.support_mode') }}
                        </div>
                      </template>
                      <div class="-mr-2">
                        <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-header-splash-500">
                          <Icon name="heroicons:x-mark" class="h-6 w-6" aria-hidden="true" />
                        </PopoverButton>
                      </div>
                    </div>
                  </div>
                  <div class="pt-4 pb-2">
                    <div class="flex items-center px-5">
                      <div class="flex-shrink-0">
                        <img class="h-10 w-10 rounded-full" :src="user.imageUrl" alt="" />
                      </div>
                      <div class="ml-3 min-w-0 flex-1">
                        <div class="text-base font-medium text-gray-800 truncate">{{ user.name }}</div>
                        <div class="text-sm font-medium text-gray-500 truncate">{{ user.email }}</div>
                      </div>
                    </div>
                    <div class="mt-3 px-2 space-y-1">
                      <button
                        v-for="item in userNavigation"
                        :key="item.name"
                        @click='changePage(item, close)'
                        class="w-full text-left rounded-md px-3 py-2 text-base font-medium hover:bg-gray-100 text-gray-900 hover:text-gray-800"
                        :class="{ 'cursor-not-allowed' : item.disabled }"
                        :disabled="item.disabled"
                      >
                        <span
                          v-if="item.disabled"
                          :aria-label="t('words.not_enough_data')"
                          data-microtip-position="right"
                          role="tooltip"
                        >
                          <span class="w-min opacity-50">{{ item.name }}</span>
                        </span>
                        <span v-else>
                          {{ item.name }}
                        </span>
                      </button>
                      <button
                        v-if="companyStore.settings.ENABLE_SETTINGS"
                        :disabled="companyStore.user.impersonationMode"
                        @click='to("/settings"); close()'
                        class="block px-4 py-2 text-sm text-gray-700"
                        :aria-label="t('words.not_available')"
                        data-microtip-position="right"
                        :role="companyStore.user.impersonationMode ? 'tooltip' : 'button'"
                      >
                        <span
                          :class="{ 'cursor-not-allowed opacity-50': companyStore.user.impersonationMode }"
                        >
                          {{ t('menu.settings') }}
                        </span>
                      </button>
                      <button @click='switchLocale' class="block px-4 py-2 text-sm text-gray-700 cursor-pointer">{{ t('ui.switch_locale') }}</button>
                      <button @click='to("/logout")' class="block px-4 py-2 text-sm text-gray-700 cursor-pointer">{{ t('ui.logout') }}</button>
                    </div>
                  </div>
                </div>
              </PopoverPanel>
            </TransitionChild>
          </div>
        </TransitionRoot>
        </Popover>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { useCoreStore, State } from '~/store/core';
import { useCompanyStore } from '~/store/company';
import { useI18n } from 'vue-i18n'
import { CoInvestorType, useUpdateUserLocaleMutation } from '~/generated/operations-airfund'

const isScrolled = inject<boolean>('isScrolled')

const companyStore = useCompanyStore()
const { t } = useI18n()
const coreStore = useCoreStore()
const router = useRouter()
const mutation = useUpdateUserLocaleMutation()

const user = computed(() => companyStore.user)
const userNavigation = computed(() => coreStore.getNavigation)

const to = (href) => {
  router.push(href)
}

const switchLocale = async () => {
  await mutation.executeMutation({
    locale: companyStore.user.preferredLanguage === 'en' ? 'fr' : 'en'
  })
  // refresh page
  window.location.reload()
}

const changePage = (page: State['navigation'][0], close: Function) => {
  if (!page?.disabled) {
    to(page.to)
    close()
  }
}

const displayCoInvestorTag = () => {
  return companyStore.selectedInvestor.coInvestorType === CoInvestorType.Usufructuary || companyStore.selectedInvestor.coInvestorType === CoInvestorType.BareOwner
}

</script>
