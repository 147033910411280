import { default as documentsSuIEiRncUxMeta } from "/usr/src/app/pages/documents.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as investments9zUJNHhYaNMeta } from "/usr/src/app/pages/investments.vue?macro=true";
import { default as loginRGcvZC480vMeta } from "/usr/src/app/pages/login.vue?macro=true";
import { default as logoutLM6hK8aOwEMeta } from "/usr/src/app/pages/logout.vue?macro=true";
import { default as _91id_930xoydHE2BcMeta } from "/usr/src/app/pages/news/[id].vue?macro=true";
import { default as index6s0GhshVDKMeta } from "/usr/src/app/pages/news/index.vue?macro=true";
import { default as _91id_93BZxSEuLtmFMeta } from "/usr/src/app/pages/products/[id].vue?macro=true";
import { default as indextGTcZpyi4NMeta } from "/usr/src/app/pages/products/index.vue?macro=true";
import { default as settingsnXXGFi4oVlMeta } from "/usr/src/app/pages/settings.vue?macro=true";
import { default as transactionsVYz99tBeCZMeta } from "/usr/src/app/pages/transactions.vue?macro=true";
import { default as visitorXxgX7egXy6Meta } from "/usr/src/app/pages/visitor.vue?macro=true";
import { default as documentsSBpLC342UmMeta } from "~/pages/documents.vue?macro=true";
export default [
  {
    name: "documents",
    path: "/documents",
    component: () => import("/usr/src/app/pages/documents.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "investments",
    path: "/investments",
    component: () => import("/usr/src/app/pages/investments.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginRGcvZC480vMeta || {},
    component: () => import("/usr/src/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutLM6hK8aOwEMeta || {},
    component: () => import("/usr/src/app/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "news-id",
    path: "/news/:id()",
    component: () => import("/usr/src/app/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/usr/src/app/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "products-id",
    path: "/products/:id()",
    component: () => import("/usr/src/app/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/usr/src/app/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/usr/src/app/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "transactions",
    path: "/transactions",
    component: () => import("/usr/src/app/pages/transactions.vue").then(m => m.default || m)
  },
  {
    name: "visitor",
    path: "/visitor",
    meta: visitorXxgX7egXy6Meta || {},
    component: () => import("/usr/src/app/pages/visitor.vue").then(m => m.default || m)
  },
  {
    name: "documents-personal",
    path: "/documents/personal",
    component: () => import("~/pages/documents.vue").then(m => m.default || m)
  },
  {
    name: "documents-funds",
    path: "/documents/funds",
    component: () => import("~/pages/documents.vue").then(m => m.default || m)
  },
  {
    name: "documents-notices",
    path: "/documents/notices",
    component: () => import("~/pages/documents.vue").then(m => m.default || m)
  },
  {
    name: "documents-subscriptions",
    path: "/documents/subscriptions",
    component: () => import("~/pages/documents.vue").then(m => m.default || m)
  }
]