import {
  Client,
  createClient,
  cacheExchange,
  fetchExchange,
  makeOperation,
  subscriptionExchange,
  mapExchange,
} from '@urql/vue'
import * as Sentry from '@sentry/vue';
import { authExchange } from '@urql/exchange-auth'
import { createClient as createWSClient } from 'graphql-ws';


export async function createGraphqlClient(): Promise<Client> {
  const { getSession } = useAuth()
  const session = await getSession()
  const config = useRuntimeConfig()
  const wsClient = createWSClient({
    url: config.public.GRAPHQL_AIRFUND_WS_ENDPOINT,
    connectionParams: () => {
      const token = session.accessToken
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        authToken: token,
      }
    },
  });

  return createClient({
    url: config.public.GRAPHQL_AIRFUND_ENDPOINT, // default endpoint
    fetchOptions: () => ({
      credentials: 'same-origin',
      // default endpoint headers
      headers: {
        Authorization: `Bearer ${session.accessToken}`
      }
    }),
    exchanges: [
      cacheExchange,
      authExchange({
        addAuthToOperation({ operation }) {
          // if clientName does not exist, we return operation without modifications
          if (!operation.context.clientName) {
            return operation
          }

          const { clientName, fetchOptions } = operation.context
          const options = typeof fetchOptions === 'function' ? fetchOptions() : fetchOptions ?? {}

          switch (clientName) {
            case 'airfund': {
              return operation // default op is airfund
            }
            case 'airdistrib': {
              // New context for endpoint ad
              const context = {
                ...operation.context,
                url: config.public.GRAPHQL_AIRDISTRIB_ENDPOINT,
              }
              return makeOperation(operation.kind, operation, context)
            }
            case 'airwealth': {
              // Endpoint aw headers
              const headers = {
                ...options.headers,
                platform: 'investor'
              }

              // New context for endpoint aw
              const context = {
                ...operation.context,
                url: config.public.GRAPHQL_AIRWEALTH_ENDPOINT,
                fetchOptions: {
                  ...options,
                  headers,
                }
              }
              return makeOperation(operation.kind, operation, context)
            }
            case 'airfund-ws': {
              const context = {
                ...operation.context,
                url: config.public.GRAPHQL_AIRFUND_WS_ENDPOINT,
                authToken: session.accessToken,
                fetchOptions: {
                  authToken: session.accessToken,
                  headers: {
                    Authorization: `Bearer ${session.accessToken}`,
                    authToken: session.accessToken,
                  }
                },
                connectionParams: {
                  authToken: session.accessToken,
                  headers: {
                    Authorization: `Bearer ${session.accessToken}`,
                    authToken: session.accessToken,
                  }
                }
              }
              return makeOperation(operation.kind, operation, context)
            }
            default: {
              throw new Error(`Unexpected object: ${clientName}`)
              return operation
            }
          }
        },
        getAuth: async () => {},
      }),
      subscriptionExchange({
        forwardSubscription(request) {
          const input = { ...request, query: request.query || '' };
          return {
            subscribe(sink) {
              const unsubscribe = wsClient.subscribe(input, sink);
              return { unsubscribe };
            },
          };
        },
      }),
      // subscriptionExchange({
      //   forwardSubscription(operation) {
      //     return subscriptionClient.request(operation);
      //   },
      // }),
      mapExchange({
        onError: (error, operation) => {
          // eslint-disable-next-line no-console
          Sentry.withScope(scope => {
            scope.setTag("kind", operation.kind);
            // (make sure to strip out sensitive data!)
            scope.setExtra("query", JSON.stringify(operation.query, null, 2));
            scope.setExtra("variables", JSON.stringify(operation.variables, null, 2));
            Sentry.captureException(error);
          });
        }
      }),
      fetchExchange,
    ],
  })
}
