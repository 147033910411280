import { defineStore } from "pinia";

export interface State {
  news: Post[],
  categories: Category[],
  activeTag: string | null
}

export interface Post {
  id: string
  title: string
  preview: string
  content: string
  image: string
  publicationDate: string
  tags: Tag[]
  authors: Author[]
  averageReadTime: number
}

interface Tag {
  label: {
    id: string
    name: string
  }
}
interface Author {
  name: string
  email: string
}

interface Category {
  code: string
  label:  string
}

export const useNewsStore = defineStore('news', {
  state: (): State => ({
    news: [],
    categories: [],
    activeTag: null
  }),
  getters: {
    filteredNews (state): Post[] {
      if (state.activeTag) {
        return state.news.filter((post: Post) => {
          const postTags = post.tags.map((tag: Tag) => tag.label.id)
          return postTags.includes(state.activeTag)
        })
      }
      return state.news
    }
  },
  actions: {
    toggleFilter (filter: string) {
      if (this.activeTag !== filter) {
        this.activeTag = filter
      }
    }
  }
})
