import { createI18n } from 'vue-i18n'
import messages from '~/i18n'

const instance = createI18n({
	legacy: false,
	locale: 'en',
	fallbackLocale: 'en',
	messages
})

export default instance

export const i18n = instance.global