import { defineStore } from "pinia";
import { Column } from "~/types/base";
import { ShareItem, Notification } from "~/types/domain/domain";
import { shareIndicator } from "./investments";
import { Post } from "./news";
import { i18n } from '~/utils/i18n';
import { useCompanyStore } from "./company";

export interface State {
  keyNumbers: {
    value: number
    code: string
  }[]
  news: Post[],
  notifications: Notification[],
  products: {
    cols: Column[],
  }
}

export const useHomeStore = defineStore('home', {
  state: (): State => ({
    keyNumbers: [],
    news: [],
    notifications: [],
    products: {
      cols: [
        {
          code: 'product',
          title: i18n.t('investments.cols.product'),
          handler: (item: ShareItem) => (item.name)
        },
        {
          code: 'investments',
          title: i18n.t('investments.cols.commitment'),
          handler: (item: ShareItem) => (item.transactions?.totalSubscription?.engagement?.value),
          component: 'BaseNumberCurrency'
        },
        {
          code: 'amountToCall',
          title: i18n.t('indicators.amount_to_call'),
          handler: (item: ShareItem) => shareIndicator(item, 'amountToCall').value,
          component: 'BaseNumberCurrency'
        },
        {
          code: 'amountCalled',
          title: i18n.t('indicators.total_amount_called'),
          handler: (item: ShareItem) => shareIndicator(item, 'amountCalled').value,
          component: 'BaseNumberCurrency'
        },
        {
          code: 'valuation',
          title: i18n.t('home.key_numbers.valuation'),
          handler: (item: ShareItem) => shareIndicator(item, 'valuation').value,
          component: 'BaseNumberCurrency'
        }
      ]
    }
  }),
  getters: {
    getSortedProductsColumns (state: State): State['products']['cols'] {
      const items = []
      const codes = ['product', ...this.getIndicatorCodes()]

      codes.forEach(code => {
        const item = state.products.cols.find(c => c.code === code)
        if (item) {
          items.push(item)
        }
      })

      return items
    },
    getKeyNumbers (state: State): State['keyNumbers'] {
      const items = []
      this.getIndicatorCodes().forEach(code => {
        const item = state.keyNumbers.find(k => k.code === code)
        if (item) {
          items.push(item)
        }
      })

      return items
    }
  },
  actions: {
    removeNotification (id: string) {
      this.notifications = this.notifications.filter(n => n.id !== id)
    },
    updateNotification (notification: Notification) {
      const index = this.notifications.findIndex(n => n.id === notification.id)
      if (index !== -1) {
        this.notifications[index] = notification
      }
    },
    getIndicatorCodes (opts: { valuationFirst: boolean } = { valuationFirst: false }): string[] {
      const { valuationFirst } = opts
      const companyStore = useCompanyStore()
      let codes = ['investments', 'amountToCall', 'amountCalled', 'valuation']

      if (!companyStore.settings.DISPLAY_CAPITAL_CALLS) {
        if (valuationFirst) {
          codes = ['valuation', 'investments'] // Valuation needs to be first in HomeBreakdownInvestmentsPanel.vue
        } else {
          codes = ['investments', 'valuation']
        }
      }

      if (!companyStore.settings.ENABLE_HOME_VALORIZATION) {
        codes = codes.filter(c => c !== 'valuation')
      }

      return codes
    }
  }
})
