import { z } from 'zod'

export const RgbaColor = z.string().regex(/^rgba\(\d{1,3},\s?\d{1,3},\s?\d{1,3}(,\s?0?\.?\d{1,3})?\)$/)
export const Gradient = z.array(RgbaColor).length(2)

export const Theme = z.object({
  colors: z.object({
    primary: z.object({
      50: RgbaColor,
      100: RgbaColor,
      200: RgbaColor,
      300: RgbaColor,
      400: RgbaColor,
      500: RgbaColor,
      600: RgbaColor,
      700: RgbaColor,
      800: RgbaColor,
      900: RgbaColor
    }),
    important: RgbaColor,
    header: z.object({
      topbar: z.object({
        transparent: RgbaColor,
        main: RgbaColor,
        text: RgbaColor,
        button: RgbaColor,
        divider: RgbaColor
      }),
      splash: RgbaColor,
      subtitle: RgbaColor,
      nav: z.object({
        active: RgbaColor,
        normal: RgbaColor,
        scrolled: RgbaColor,
        bg: RgbaColor,
        hover: RgbaColor
      }),
      image: z.boolean().optional(),
      gradient: Gradient
    }),
    table: z.object({
      gradient: Gradient,
      primary: RgbaColor
    }),
    layout: z.object({
      bg: RgbaColor
    }),
    notification: z.object({
      active: RgbaColor,
      hover: RgbaColor
    }),
    chart: z.object({
      line: z.object({
        engagement: z.object({
          base: RgbaColor,
          gradient: Gradient
        }),
        fundcall: z.object({
          base: RgbaColor,
          gradient: Gradient
        }),
        distribution: z.object({
          base: RgbaColor,
          gradient: Gradient
        }),
      }),
      bar: z.object({
        engagement: z.object({
          gradient: Gradient
        }),
        fundcall: z.object({
          gradient: Gradient
        }),
        valorisation: z.object({
          base: RgbaColor,
          gradient: Gradient
        }),
        distribution: z.object({
          base: RgbaColor,
          gradient: Gradient
        })
      }),
      jauge: z.object({
        gradient: Gradient
      }),
      donut: z.array(RgbaColor)
    })
  }),
  fonts: z.object({
    sans: z.array(z.string()).optional(),
    serif: z.array(z.string()).optional(),
    title: z.array(z.string()).optional(),
    h2: z.array(z.string()).optional(),
  })
})

export default interface Theme extends z.infer<typeof Theme> {}