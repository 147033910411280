export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Libre+Caslon+Text&display=swap"}],"style":[],"script":[{"hid":"matomo","children":"\n            if (document.domain.includes('investor.airfund.io')) {\n              var _paq = window._paq = window._paq || [];\n              /* tracker methods like \"setCustomDimension\" should be called before \"trackPageView\" */\n              _paq.push(['setDocumentTitle', \"undefined\"]);\n              // Share the tracking cookie across subdomains.\n              _paq.push(['setCookieDomain', '*.investor.airfund.io']);\n              // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'\n              _paq.push(['setDomains', '*.investor.airfund.io']);\n              _paq.push(['trackPageView']);\n              _paq.push(['enableLinkTracking']);\n              (function() {\n                var u=\"//matomo.silk.am/\";\n                _paq.push(['setTrackerUrl', u+'matomo.php']);\n                _paq.push(['setSiteId', '2']);\n                var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];\n                g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);\n              })();\n            }\n          "}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000