export const configShapeToTailwindConfigCSSVariables = (config: any): Record<string, any> => { // TODO: type config
	let result : any = {
		colors: {},
		fonts: {
			sans: [],
			serif: [],
			title: [],
			h2: [],
		}
	}
	const strBuilderColor = (obj: any, prefixes: string[] = []) => {
		for (const key of Object.keys(obj)) {
			const slug = [...prefixes, key].join('-')
			const nestedKeys = [...prefixes, key].reduce((acc, cur) => {
				acc += `["${cur}"]`
				return acc
			}, '')

			// si obj[key] est une string, on ajoute la variable css
			if (typeof obj[key] === 'string' || obj[key] === null) {
				eval(`result.colors${nestedKeys} = "var(--colors-${slug})"`)
				continue
			}

			// si obj[key] est un boolean, on ajoute la valeur
			if (typeof obj[key] === 'boolean') {
				eval(`result.colors${nestedKeys} = obj[key]`)
				continue
			}

			// si obj[key] est un array, on ajoute les variables css
			if (Array.isArray(obj[key])) {
				const array = (obj[key] as string[]).map((color, i) => `var(--colors-${slug}-${i})`)
				eval(`result.colors${nestedKeys} = array`)
				continue
			}

			// si obj[key] est un objet, on rappelle la fonction (recursivité)
			if (typeof obj[key] === 'object') {
				eval(`result.colors${nestedKeys} = {}`)
				strBuilderColor(obj[key], [...prefixes, key])
			}
		}
	}
	const strBuilderFonts = (obj: any, prefix = '') => {
		for (const key of Object.keys(obj)) {
			result.fonts[key] = (obj[key] as string[]).map((font, i) => `var(--fonts-${key}-${i+1})`)
		}
	}
	try { strBuilderColor(config.colors) } catch (e) {}
	try { strBuilderFonts(config.fonts) } catch (e) {}
	return result
}

export const objectConfigToCSSVariables = (config: any): Record<string, any> => {
	const result: Record<string, any> = {}
	const strBuilderColor = (obj: any, prefixes: string[] = []) => {
		const keys = Object.keys(obj)
		for (const key of keys) {
			if(typeof obj[key] !== 'object' || !obj[key]) {
				const tmp = prefixes.length > 0 ? prefixes.join('-')+'-'+key : key
				result[`--colors-${tmp}`] = obj[key] || '\'\''
			} else if (obj[key]){
				strBuilderColor(obj[key], [...prefixes, key])
			}
		}
	}
	const strBuilderFonts = (obj: any) => { // TODO: type config
		const keys = Object.keys(obj)
		for (const key of keys) {
			obj[key].forEach((font: string, i: number) => {
				result[`--fonts-${key}-${i+1}`] = font
			})
		}
	}
	try { strBuilderColor(config.colors) } catch (e) {}
	try { strBuilderFonts(config.fonts) } catch (e) {}
	return result
}