import Toast, { PluginOptions, POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

export default defineNuxtPlugin(nuxtApp => {
  const options: PluginOptions = {
    position: POSITION.BOTTOM_CENTER,
    timeout: 3000,
    closeOnClick: false,
    maxToasts: 3
  }

  nuxtApp.vueApp.use(Toast, options)
})
